<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-10 17:03:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-10 17:57:03
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-row :gutter="8">
            <a-col :span="6">
                <div class="city_css">
                    <a-tree
                    checkable
                    :tree-data="addressList"
                    :replace-fields="replaceFields"
                    :auto-expand-parent="autoExpandParent"
                    v-model="rowData.cityIdList" />
                </div>
                
            </a-col>
            <a-col :span="18">
                <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="所属大区" prop="areaId">
                        <a-select placeholder="请选择" v-model="rowData.areaId">
                            <a-select-option :value="item.id" v-for="(item, index) in regionList" :key="index">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="事务所名称" prop="name">
                        <a-input v-model="rowData.name" placeholder="请输入名称"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="事务所地址">
                        <PCD
                            ref="pcd"
                            placeholder="请选择区域"
                            :province.sync="rowData.province"
                            :city.sync="rowData.city"
                            :district.sync="rowData.county"
                            :provinceId.sync="rowData.provinceId"
                            :cityId.sync="rowData.cityId"
                            :districtId.sync="rowData.countyId">
                        </PCD>
                    </a-form-model-item>
                    <a-form-model-item label="详细地址" prop="address">
                        <a-input v-model="rowData.address" placeholder="详细地址"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="备注" prop="">
                        <a-textarea v-model="rowData.remark" placeholder="备注"></a-textarea>
                    </a-form-model-item>
                </a-form-model>
            </a-col>
        </a-row>
    </a-modal>
</template>

<script>
import { editMhiacFirm, selectByIdMhiacFirm, addMhiacFirm } from '../api/MhiacFirmApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入事务所名称', trigger: 'blur' }
                ],
                areaId: [
                    { required: true, message: '请选择所属大区', trigger: 'change' }
                ],
                area: [
                    { required: true, message: '请输入大区名称', trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注', trigger: 'blur' }
                ],
            },
            addressList: [],
            autoExpandParent: true,
            replaceFields: {
                children: 'children',
                title: 'name',
                key: 'id'
            },
            regionList: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            // 获取左侧区域数据
            this.axios.get('/api/base/system/baseArea/listCity/children').then(res => {
                this.addressList = res.body
            })
            // 获取大区数据
            this.axios.get('/api/firm/mhiac/mhiacArea/listAll').then(res => {
                this.regionList = res.body
            })
            if(handle == 'edit') {
                this.axios.get('/api/firm/mhiac/mhiacFirm/selectById/' + row.id).then(res => {
                    this.rowData = res.body
                    if(res.body.length != 0) {
                        this.$set(this.rowData, 'provinceId', res.body.provinceId)
                        this.$set(this.rowData, 'province', res.body.province)
                        this.$set(this.rowData, 'cityId', res.body.cityId)
                        this.$set(this.rowData, 'city', res.body.city)
                        this.$set(this.rowData, 'countyId', res.body.countyId)
                        this.$set(this.rowData, 'county', res.body.county)
                        this.$set(this.rowData, 'address', res.body.address)
                        this.$nextTick(() => {
                            this.$refs.pcd.setPCD()
                        })
                    }
                })
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addMhiacFirm(this.rowData) : await editMhiacFirm(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>
    .city_css {
        height: 300px;
        overflow-y: auto;
    }
</style>
